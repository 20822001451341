import { render, staticRenderFns } from "./Feedback.vue?vue&type=template&id=04526f10&scoped=true&"
import script from "./Feedback.vue?vue&type=script&lang=js&"
export * from "./Feedback.vue?vue&type=script&lang=js&"
import style0 from "./Feedback.vue?vue&type=style&index=0&id=04526f10&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04526f10",
  null
  
)

export default component.exports