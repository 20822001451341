<template>
  <ValidationObserver v-slot="{ invalid }" ref="valobs">
    <div
      ref="feedbackEnclosingDiv"
      class="feedback-form-style"
      style="border-radius: 5px 5px 5px 5px"
    >
      <div
        v-show="localScreen !== 'finish' && localScreen !== 'feedbackContact'"
      >
        <p
          style="padding-top: 0.5em"
          class="has-text-custom-one has-text-centered has-text-weight-semibold is-size-6 is-italic"
        >
          {{ $t('feedback-service-intro') }}
        </p>
        <p
          style="padding: 0.5em 3em 0 3em"
          class="has-text-centered has-text-black has-text-weight-semibold is-size-5"
        >
          {{ $t('feedback-service-question') }}
        </p>
        <b-rate
          v-model="feedbackRating"
          icon-pack="fas"
          size="is-large"
          style="padding-top: 0.5em; justify-content: center"
          @change="processFeedback(feedbackRating)"
        />

        <b-field
          v-if="ratingSelected"
          style="padding-top: 0.5em"
          :label="$t('feedback-nps-question')"
        >
          <b-slider
            v-model="feedbackNps"
            :type="sliderClass"
            :min="-1"
            :max="10"
            ticks
            :tooltip="false"
            rounded
          />
        </b-field>

        <p
          v-if="feedbackNps >= 0"
          class="has-text-weight-semibold has-text-black has-text-centered"
          style="padding-bottom: 1em"
        >
          {{ $t('You selected') }}: {{ feedbackNps }} {{ $t('out of 10') }}
          <span
            v-if="feedbackNps === 9 || feedbackNps === 10"
            style="padding-left: 0.5em"
            class="feedback-9-10"
          >
            &#128512;
          </span>
        </p>
        <p
          v-else
          class="has-text-weight-semibold has-text-black has-text-centered"
          style="padding-bottom: 1em"
        >
          &nbsp;
        </p>

        <textarea
          v-if="ratingSelected"
          v-model="feedbackText"
          class="textarea-style"
          :placeholder="$t(`Is there anything you'd like to share with us?`)"
          type="textarea"
          rows="5"
          maxlength="300"
        />

        <div style="float: right" class="buttons">
          <b-button
            v-if="showNext"
            type="is-feedback-buttons"
            style="z-index: 999"
            @click.prevent="showFeedbackContactScreen"
          >
            Next
          </b-button>
        </div>
      </div>

      <div v-show="localScreen === 'feedbackContact'">
        <p
          class="has-text-centered has-text-black has-text-weight-semibold"
          style="overflow-wrap: break-word; padding: 1em 3em 0em 3em"
        >
          {{
            $t(`Leave your contact details if you'd like us to get back to you`)
          }}
        </p>
        <b-field
          style="padding-top: 1em; text-align: center"
          grouped
          group-multiline
        >
          <div class="full-width">
            <b-input
              v-model="feedbackName"
              name="contactName"
              :placeholder="$t('Name')"
              type="text"
              icon="file-signature"
            />
            <p style="color: red; max-width: 90%; min-height: 2em" />
          </div>
          <ValidationProvider
            name="email"
            rules="email"
            class="pad-top full-width"
          >
            <div slot-scope="{ errors }">
              <b-input
                v-model="feedbackEmail"
                name="email"
                :placeholder="$t('Email')"
                type="text"
                icon="envelope-open-text"
              />
              <p style="color: red; max-width: 90%; min-height: 2em">
                {{ errors[0] }}
              </p>
            </div>
          </ValidationProvider>
          <div class="pad-top full-width">
            <b-input
              v-model="feedbackPhone"
              name="contactPhone"
              :placeholder="$t('Phone')"
              type="text"
              icon="mobile"
            />
            <div style="min-height: 2em" />
          </div>
        </b-field>
        <div style="float: right; padding-top: 2.5em" class="buttons">
          <b-button
            class="is-feedback-buttons button"
            :disabled="invalid"
            @click.prevent="submitFeedbackAll"
          >
            Submit
          </b-button>
        </div>
      </div>
      <div class="footer-layout">
        <img
          style="max-width: 16em; max-height: 2.5em"
          :src="require(`@/assets/img/${$omwConfig.display.companyLogo}`)"
        />
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from 'vee-validate';
setInteractionMode('eager');

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    screen: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      orientation: 'landscape',
      localScreen: '',
      feedbackText: '',
      feedbackEmail: '',
      feedbackPhone: '',
      feedbackName: '',
      feedbackRating: 0,
      feedbackNps: -1,
      ratingSelected: false,
    };
  },
  computed: {
    sliderClass() {
      let returnVal;
      if (this.feedbackNps < 7) {
        returnVal = 'is-feedback-bad';
      } else if (this.feedbackNps >= 7 && this.feedbackNps < 9) {
        returnVal = 'is-feedback-ok';
      } else {
        returnVal = 'is-feedback-good';
      }
      return returnVal;
    },
    showNext() {
      return this.ratingSelected && this.feedbackNps > -1;
    },
  },
  watch: {
    orientation: {
      handler: function (newVal) {
        if (newVal === 'landscape') {
          this.$refs.feedbackEnclosingDiv.style['overflow-y'] = 'scroll';
        } else {
          this.$refs.feedbackEnclosingDiv.style['overflow-y'] = 'none';
        }
      },
    },
    localScreen: {
      handler: function (screen) {
        if (screen === 'finish') {
          this.$buefy.toast.open({
            message: this.$t(
              `We read all feedback, thank you for taking the time to help us improve our service for you.`,
            ),
            type: 'is-primary has-text-weight-semibold',
            duration: 200000,
            position: 'is-bottom',
          });
          this.$emit('close');
        }
      },
    },
  },
  created() {
    this.orientation = this.getOrientation(window.orientation);
    window.addEventListener(
      'orientationchange',
      () => {
        this.orientation = this.getOrientation(window.orientation);
      },
      false,
    );
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleEscape);
  },
  mounted() {
    this.localScreen = this.screen;
  },
  methods: {
    processFeedback() {
      // Use nextTick to ensure feedbackRating is updated prior to processing
      this.$nextTick(() => {
        this.ratingSelected = true;
        this.showFeedbackTextScreen(this.feedbackRating);
      });
    },
    showFeedbackTextScreen(rating) {
      this.feedbackRating = rating;
      this.localScreen = 'feedbackText';
    },
    showFeedbackContactScreen() {
      this.localScreen = 'feedbackContact';
    },
    submitFeedbackAll() {
      const connection =
        navigator.connection ||
        navigator.mozConnection ||
        navigator.webkitConnection;
      this.$emit('feedback', {
        nps: this.feedbackNps,
        message: this.feedbackText,
        name: this.feedbackName,
        email: this.feedbackEmail,
        phone: this.feedbackPhone,
        rating: this.feedbackRating,
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
        connection: connection ? connection.effectiveType : undefined,
        product: navigator.product,
      });
      this.localScreen = 'finish';
    },
  },
});
</script>

<style scoped lang="scss">
@import '@/sass/variables.scss';

.feedback-form-style {
  background-color: white;
  opacity: 1;
  padding: 1em 1em 1em 1em;
  margin: 1em 1em 1em 1em;
  height: 550px;
  z-index: 999;
}
.textarea-style {
  resize: none !important;
  outline: none;
  border: none;
  background-color: #f5f5f5;
  font-size: 1rem;
  width: 100%;
  padding: 0.5em 0.5em 0.5em 0.5em;
}
.full-width {
  width: 100%;
}
.pad-top {
  padding-top: 0.7em;
}
.footer-layout {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 800;
  margin-top: 1em;
  margin-bottom: 2em;
  margin-left: -2em;
  text-align: center;
}
</style>
